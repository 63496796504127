<template>
  <main class="login">
    <div class="login-container page-container container">
      <div class="login-box">
        <h3 class="login-title">Login</h3>
        <div class="login-form">
          <form>
            <div class="form-group">
              <label for="user_login" class="login-form-label">Email</label>
              <input
                class="form-control"
                id="user_login"
                type="text"
                autocomplete="new-password"
                v-model="user.email"
                required
                autofocus
              />
            </div>
            <div class="form-group">
              <label class="login-form-label" for="user_password"
                >Password</label
              >
              <input
                class="form-control"
                type="password"
                id="user_password"
                autocomplete="new-password"
                v-model="user.password"
                required
              />
            </div>
            <div class="form-group login-form-reset">
              <router-link class="btn-link" :to="`/pages/password/reset`"
                >Forgot your password?</router-link
              >
            </div>

            <button
              type="submit"
              class="login-form-submit btn btn-primary"
              @click.prevent="loginHandler"
            >
              Sign in
            </button>
          </form>
          <div class="login-text">
            In case of your account is inaccessible, please contact your account
            manager/support.
          </div>
          <div class="login-errorMsg text-danger" v-if="errorMsg">
            please check your user name or password.
          </div>
        </div>
      </div>
      <div class="login-bottomBg">
        <img :src="`${publicPath}img/login-bg.jpg`" alt="" />
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Login',
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
      user: {
        email: '',
        password: ''
      },
      errorMsg: false
    }
  },
  mounted() {
    const status = this.$route.query.status
    if (status === 'logout') {
      window.location = '/pages/login'
    }
    if (this.userSelf) {
      this.$router.push('/connections')
    }
  },
  computed: {
    ...mapState({
      userSelf: state => state.user.self
    })
  },
  methods: {
    ...mapActions(['login']),
    async loginHandler() {
      let status = await this.login(this.user)
      if (status === 201) {
        this.userSelf.signedInWithTemporaryPassword
          ? this.$router.push('/pages/password/setup')
          : this.$router.push('/connections')
      } else {
        this.errorMsg = true
      }
    }
  }
}
</script>
